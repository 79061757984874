import { Config } from 'src/components/context/config';

export interface CustomerBase {
  firstName: string;
  lastName: string;
  phone: string | null;
  email: string | null;
  optoutCalls: boolean;
  optoutSms: boolean;
  optoutEmails: boolean;
}

export interface CustomerBlock extends CustomerBase {
  comments?: string;
  location?: string;
  zip?: string;
}

export interface CustomerBlockDefaultValues extends CustomerBase {
  comments?: string;
  location: string;
  zip: string;
}

export function returnCustomerBlockDefaultValues(
  data?: CustomerBlock,
  config?: Config
): CustomerBlockDefaultValues {
  return {
    firstName: data?.firstName ?? '',
    lastName: data?.lastName ?? '',
    email: data?.email ?? '',
    phone: data?.phone?.replace(/(\+1)/g, '') || '',
    comments: data?.comments ?? '',
    optoutCalls: data?.optoutCalls ?? config?.forms?.lead?.explicitOptIn ? true : false,
    optoutSms:
      data?.optoutSms ?? (config?.forms?.lead?.explicitOptIn || config?.forms?.lead?.separateSms)
        ? true
        : false,
    optoutEmails: data?.optoutEmails ?? config?.forms?.lead?.explicitOptIn ? true : false,
    location: data?.location ?? '',
    zip: data?.zip ?? '',
  };
}
