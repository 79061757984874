import { Control } from 'react-hook-form';
import { useConfig } from '../context/config';
import NegativeToggle from '../controls/NegativeToggle';
import { NegativeToggleError } from '../controls/NegativeToggleError';
import './HardPullGroup.css';
import NegativeCheckbox from '../controls/NegativeCheckbox';

export function TermsGroup({ control }: { control: Control<any> }) {
  const config = useConfig()!;
  const privacyPolicy = () => (
    <a
      href={`https://${config.websiteDomain}/privacy-policy/`}
      target="_blank"
      className="u-blue-link"
      rel="noreferrer"
    >
      {' '}
      privacy policy{' '}
    </a>
  );

  const termsOfService = () => (
    <a
      href={`https://${config.websiteDomain}/terms-of-service/`}
      target="_blank"
      className="u-blue-link"
      rel="noreferrer"
    >
      {' '}
      terms of service{' '}
    </a>
  );

  const smsLabel = () => (
    <>
      {config.forms?.lead?.explicitOptInText ||
        `I agree to receive SMS messages from ${config.dealershipName}. Message & data rates may apply. Reply STOP
            to opt out or HELP for more information.`}{' '}
      View our {termsOfService()} and
      {privacyPolicy()} at our website.
    </>
  );

  return (
    <div>
      <div className="terms--holder">
        <div className="terms--title">How may we follow up with you? </div>
      </div>

      <div className="terms--small-text">
        {config.forms?.lead?.separateSms ? (
          <NegativeCheckbox control={control} name="optoutSms" label={smsLabel()} />
        ) : (
          <span>{smsLabel()}</span>
        )}
      </div>

      <div className="u-display-flex">
        <NegativeToggle control={control} name="optoutCalls" label="Phone" />
        {!config.forms?.lead?.separateSms && (
          <NegativeToggle control={control} name="optoutSms" label="SMS" />
        )}
        <NegativeToggle control={control} name="optoutEmails" label="Email" />
      </div>
      {config.forms?.lead?.requireSms && (
        <>
          <div className="terms--title">You must consent to SMS messages to continue</div>
          <NegativeToggleError control={control} name="optoutSms" />
        </>
      )}
      {/* To do: pass in array of controls and look for error in any of them */}
      <NegativeToggleError control={control} name="optoutCalls" />
    </div>
  );
}
