import { useMemo } from 'react';
import { Control, useController } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import './Checkbox.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/pro-regular-svg-icons';
import { faCheckSquare } from '@fortawesome/pro-solid-svg-icons';
import { Colors } from 'src/types/colors';
// import RichContent from '../form/RichContent';

interface ToggleProps {
  name: string;
  control: Control;
  label: any;
  className?: string;
}

export default function NegativeCheckbox({ name, control, label, className }: ToggleProps) {
  const {
    field: { value, ...field },
    fieldState: { invalid, isTouched, isDirty },
    formState: { errors, isSubmitted },
  } = useController({
    name,
    control,
  });

  const renderClass = useMemo(() => {
    const classes = className ? [className, 'form--checkbox', 'is-negative'] : ['form--checkbox'];
    if ((isSubmitted || isTouched) && isDirty) classes.push('is-entered');
    if ((isSubmitted || isTouched) && invalid) classes.push('is-error-field');
    return classes.join(' ');
  }, [className, invalid, isDirty, isSubmitted, isTouched]);

  return (
    <>
      <div className="form--checkbox--holder">
        <label className={renderClass} htmlFor={name}>
          <input
            id={`${name}`}
            type="checkbox"
            className="form--checkbox--checkbox"
            value={value}
            {...field}
            checked={value}
          />
          <div className="form--checkbox--box">
            <FontAwesomeIcon
              icon={!value ? faCheckSquare : faSquare}
              color={!value ? Colors.Blue1 : Colors.Black}
            />
          </div>
        </label>

        <div className="text--tiny">
          {label}
          {/* <RichContent content={label} /> */}
        </div>
      </div>
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <div className="form--error">{!value && message}</div>}
      />
    </>
  );
}
